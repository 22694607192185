import {
    api,
    validateHostname,
    fetchUserDefaultPosition,
    fetchUserPosition,
    positionToLatLngLiteral,
    retailTuneAutocompleteHandler,
    createAutocompleteHandler,
    createDebounceFn,
    getStoreOpeningTime,
    getExpirable,
    USER_POSITION_CONSENT,
    DEFAULT_POSITION,
    USER_POSITION,
    setExpirable,
    createExpirationValue,
    createPosition,
    getDevice,
    getRadialDistanceFn,
    sortStoresByPriority,
    isInViewport,
  } from "@retailtune/utils";
  import {
    googleAutocompleteHandler,
    selectMapZoomFromPredictionLayer,
    CustomRenderer,
    getGoogleMapsLink,
  } from "@retailtune/google-maps-utils";
  import {
    createAutocomplete,
    createToastMessagesContainer,
    createScrollButton,
    createSidebar,
  } from "@retailtune/vanilla-ui-core";
  import { Translations } from "../common/translations";
  import { LanguageData, fetchStores, rtEvent } from "../common/utils";
  import {
    MarkerClusterer,
    GridAlgorithm,
    SuperClusterAlgorithm,
  } from "@googlemaps/markerclusterer";
  
  import { Store } from "@retailtune/types/lib/store";
  import { Position } from "@retailtune/types/lib/geolocation";
  import { PredictionData } from "@retailtune/types/lib/autocomplete";
  import { mapStyles } from "../common/mapStyles";
  
  import "@retailtune/vanilla-ui-core/styles/autocomplete/Autocomplete.css";
  import "@retailtune/vanilla-ui-core/styles/sidebar/Sidebar.css";
  import "@retailtune/vanilla-ui-core/styles/back-to-top/Back-to-top.css";
  import "@retailtune/vanilla-ui-core/styles/toast/Toast.css";
  import { CTAOrigin } from "@retailtune/types/lib/analytics";
  
  // * Initial Global State
  declare const retailtune: {
    rtKey: string;
    language: string;
    allLanguages: LanguageData[];
    serverTime: number;
    assetBaseUrl: string;
    homepageUrl: string;
    translations: Translations;
    storesFilter: { storeType: string | null };
    weekdayNames: string[];
  };
  
  // * Global State
  let hostname: string;
  let toast: (msg: string) => void;
  let showSidebar: (shouldShow: boolean) => void;
  let updateScrollingElement: (el: HTMLElement) => void;
  
  let currentDevice = getDevice();
  
  // position related info
  let defaultPosition: Position;
  
  let position: {
    defaultPosition: Position;
    userPosition: Position;
    currentPosition: Position;
    directionsOrigin: Position;
  };
  
  // store related info
  let stores: Store[];
  let filteredStores: Store[];
  let visibleStores: Store[];
  let uniqueStoreCountries: string[];
  let storeMarkers: google.maps.Marker[];
  let storesMarkersMap: Map<number, google.maps.Marker>;
  let storeCardsMap: Map<number, HTMLElement>;
  let storeTypesMap: Map<string, string>;
  
  // Google Map related info
  let googleMap: google.maps.Map;
  let currentPositionMarker: google.maps.Marker;
  let infoWindow: google.maps.InfoWindow;
  let markerCluster: MarkerClusterer;
  
  // Google Driving Direction Service
  let directionsStore: Store | null = null;
  let directionsStoreMarker: google.maps.Marker | null = null;
  let travelMode: google.maps.TravelMode;
  let directionService: google.maps.DirectionsService;
  let directionsRenderer: google.maps.DirectionsRenderer;
  
  // position related
  let userPositionUsageConsent = getExpirable<boolean>(USER_POSITION_CONSENT);
  let userHasBeenGeolocated = false;
  
  // events related
  let lastStoreClicked: {store: Store, origin: CTAOrigin } | null = null;
  
  // DOM related
  type HtmlCache =
    | "storelocator"
    | "consentModal"
    | "directionsPane"
    | "directionsSteps"
    | "backToNearestStoresBtn";
  const htmlMap = new Map<HtmlCache, HTMLElement>();
  
  async function main() {
    const cachedDefaultPosition = getExpirable<Position>(DEFAULT_POSITION);
    const cachedUserPosition = getExpirable<Position>(USER_POSITION);
  
    let defaultPositionPromise = cachedDefaultPosition
      ? Promise.resolve(cachedDefaultPosition)
      : fetchUserDefaultPosition(retailtune.rtKey);
  
    // * 1) Hostname validation + data fetch
    [hostname, stores, defaultPosition] = await Promise.all([
      validateHostname(retailtune.rtKey),
      fetchStores(retailtune.rtKey, retailtune.language),
      defaultPositionPromise,
    ]);
  
    // * 2) data structure and variables initialization
    position = {
      defaultPosition: defaultPosition,
      userPosition: cachedUserPosition ?? defaultPosition,
      currentPosition: cachedUserPosition ?? defaultPosition,
      directionsOrigin: cachedUserPosition ?? defaultPosition,
    };
  
    const mapContainerEl = document.getElementById("rt_map")!;
  
    let mapCenter = positionToLatLngLiteral(position.currentPosition);
  
    googleMap = new google.maps.Map(mapContainerEl, {
      center: mapCenter,
      zoom: 10,
      styles: mapStyles,
    });
  
    infoWindow = new google.maps.InfoWindow();
  
    currentPositionMarker = new google.maps.Marker({
      position: mapCenter,
      map: googleMap,
      icon: `${retailtune.assetBaseUrl}/img/pin/pin-user.svg`,
    });
  
    directionService = new google.maps.DirectionsService();
    directionsRenderer = new google.maps.DirectionsRenderer({
      map: googleMap,
      markerOptions: { visible: false },
      polylineOptions: {
        strokeWeight: 3,
        strokeColor: "#000",
      },
    });
    travelMode = google.maps.TravelMode.DRIVING;
 
    const _storeCountriesSet = new Set<string>();
    storeTypesMap = new Map<string, string>();
    storesMarkersMap = new Map<number, google.maps.Marker>();
    storeCardsMap = new Map<number, HTMLElement>();
  
    for (let i = 0; i < stores.length; ++i) {
      const store = stores[i];
      // add store country to the set of country tags
      _storeCountriesSet.add(store.country.tagISO31661Alpha2);

      // pairing storeTypeLabel with storeType for every store into a Map structure
      for (let j = 0; j < store.storeTypeLabels.length; ++j) {
        if (!storeTypesMap.has(store.storeTypeLabels[j])) {
          storeTypesMap.set(store.storeTypeLabels[j], store.storeTypes[j]);
        }
      }
  
      // create store cards map
      storeCardsMap.set(store.id, createStoreCard(store));
  
      // create marker object and saveing it into storeMarkers array
      const storePosition = { lat: store.latitude, lng: store.longitude };
      const marker = new google.maps.Marker({
        position: storePosition,
        icon: `${retailtune.assetBaseUrl}/img/pin/pin-destination.svg`,
      });
  
      marker.addListener("click", () => {
        infoWindow.setContent(createInfoWindow(store));
        infoWindow.open({ anchor: marker });
  
        if (lastStoreClicked) {
          // user clicked on store card, marker click was simulated
          googleMap.setOptions({
            center: storePosition,
            zoom: 12,
          });
        } else {
          // user directly clicked on marker
          lastStoreClicked = {store, origin: 'map'};
        }
  
        google.maps.event.trigger(googleMap, "bounds_changed");
      });
  
      storesMarkersMap.set(store.id, marker);
    }

    uniqueStoreCountries = Array.from(_storeCountriesSet);
  
    markerCluster = new MarkerClusterer({
      map: googleMap,
      markers: Array.from(storesMarkersMap.values()),
      renderer: new CustomRenderer({
        clusterIcon: `${retailtune.assetBaseUrl}/img/pin/cluster.svg`,
        clusterLabelColor: "#000000",
      }),
    });
  
    // initialize HTML map
    htmlMap.set("storelocator", document.getElementById("rt_storelocator")!);
    htmlMap.set(
      "consentModal",
      document.getElementById("rt_position_consent_modal")!
    );
    htmlMap.set("directionsPane", document.getElementById("rt_directions_pane")!);
    htmlMap.set(
      "directionsSteps",
      document.getElementById("rt_directions_instructions")!
    );
    htmlMap.set(
      "backToNearestStoresBtn",
      document.getElementById("rt_back_to_nearest_stores")!
    );
  
    // * 3) UI initialization
  
    // autocomplete initialization
    const searchHandler = createAutocompleteHandler(
      retailTuneAutocompleteHandler(retailtune.rtKey, {
        language: retailtune.language,
        countries: uniqueStoreCountries
      }),
      googleAutocompleteHandler()
    );
  
    const mainPredictionClickHandler = (prediction: PredictionData) => {
      position.currentPosition = createPosition({
        latitude: prediction.latitude,
        longitude: prediction.longitude,
      });
      
      if (directionsStore) {
        clearDirections();
      }
      
      const newCenter = positionToLatLngLiteral(position.currentPosition);
      
      currentPositionMarker.setPosition(newCenter);
      googleMap.setOptions({
        center: newCenter,
        zoom: selectMapZoomFromPredictionLayer(prediction),
      });
  
  
      rtEvent({ category: "StoreLocator", action: "Click", label: "FreeSearch" });
    };
  
    // create main autocomplete element
    createAutocomplete({
      anchor: document.getElementById("rt_main_autocomplete")!,
      searchHandler,
      predictionClickHandler: mainPredictionClickHandler,
      placeholder: retailtune.translations.k_autocomplete_placeholder, 
      zeroResultsMessage:
        retailtune.translations.k_autocomplete_zero_results_message,
    });
  
    // create driving directions autocomplete element
  
    const directionsPredictionClickHandler = (prediction: PredictionData) => {
      position.directionsOrigin = createPosition({
        latitude: prediction.latitude,
        longitude: prediction.longitude,
      });
      createDirectionsInstructions();
      rtEvent({
        category: "StoreLocator",
        action: "Click",
        label: "FreeSearchDirections",
      });
    };
  
    createAutocomplete({
      anchor: document.getElementById("rt_directions_autocomplete_container")!,
      searchHandler,
      predictionClickHandler: directionsPredictionClickHandler,
      placeholder: retailtune.translations.k_autocomplete_placeholder, 
      zeroResultsMessage:
        retailtune.translations.k_autocomplete_zero_results_message,
    });
    
    // create travel modes for directions pane
    const travelModesEl = document.getElementById("rt_travel_modes")!;
    travelModesEl.appendChild(
      createTravelMode(google.maps.TravelMode.WALKING, "/img/icon/walking.svg")
    );
    travelModesEl.appendChild(
      createTravelMode(google.maps.TravelMode.DRIVING, "/img/icon/driving.svg")
    );
    travelModesEl.appendChild(
      createTravelMode(google.maps.TravelMode.TRANSIT, "/img/icon/transit.svg")
    );
  
    // create toast message container
    [toast] = createToastMessagesContainer({
      anchor: htmlMap.get("storelocator")!,
      position: "top-right",
    });
  
    // create languages sidebar
    [showSidebar] = createSidebar({
      anchor: htmlMap.get("storelocator")!,
      position: "right",
      content: createLanguageSidebarContent(),
    });
  
    // create back to top button
    const storesScrollingEl = document.getElementById("rt_stores_area")!;
    [updateScrollingElement] = createScrollButton({
      anchorEl: storesScrollingEl,
      scrollingEl: storesScrollingEl,
    });
  
    // * 4) Startup operations
    verifyUserPositionConsent();
    createFilteredStores();
  
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("code")) {
      directionsStore =
        stores.find((s) => s.storeCode === queryParams.get("code")) ?? null;
      createDirectionsInstructions();
    }
  
    // * 5) Event handling
  
    const windowResizeDebounce = createDebounceFn()(200);
    window.addEventListener("resize", () =>
      windowResizeDebounce(() => (currentDevice = getDevice()))
    );
  
    // add listener to bounds_changed events for google map
    const boundsChangedDebounce = createDebounceFn()(200);
    googleMap.addListener("bounds_changed", () =>
      boundsChangedDebounce(() => {
        
        // update visible stores
        createVisibleStores();
  
        // add scroll into view of store card if bounds changed was triggered by a click on a marker
        if (lastStoreClicked) {
          const { store, origin } = lastStoreClicked;
          const storeEl = storeCardsMap.get(store.id)!;
          if (storeEl && !isInViewport(storeEl)) {
            storeEl.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
  
          handleStoreEvent("Store", store, origin);
  
          lastStoreClicked = null;
        }
  
      })
    );
  
    // add listeners for click events on consent modal 'yes' and 'no' buttons
    const modalYesBtn = document.getElementById("rt_btn_consent_modal_yes")!;
    modalYesBtn.onclick = () => {
      // save user consent and update application state
      setExpirable(USER_POSITION_CONSENT, {
        value: JSON.stringify(true),
        expiration: createExpirationValue(1, "years"),
      });
      userPositionUsageConsent = true;
  
      geolocateUser();
      htmlMap
        .get("consentModal")!
        .classList.remove("rt-position-consent-modal--visible");
  
      rtEvent({ category: "StoreLocator", action: "Geo", label: "Agree" });
    };
  
    const modalNoBtn = document.getElementById("rt_btn_consent_modal_no")!;
    modalNoBtn.onclick = () => {
      htmlMap
        .get("consentModal")!
        .classList.remove("rt-position-consent-modal--visible");
      rtEvent({ category: "StoreLocator", action: "Geo", label: "Disagree" });
    };
  
    const nearestStoreBtn = document.getElementById("rt_nearest_stores_btn")!;
    nearestStoreBtn.onclick = () => {
      if (directionsStore) {
        clearDirections();
      }
      verifyUserPositionConsent();
      rtEvent({
        category: "StoreLocator",
        action: "Click",
        label: "FindNearestStore",
      });
    };
  
    const languageSidebarBtn = document.getElementById(
      "rt_language_sidebar_btn"
    )!;
    languageSidebarBtn.onclick = () => showSidebar(true);
  
    htmlMap.get("backToNearestStoresBtn")!.onclick = () => {
      do {
        const zoom = googleMap.getZoom();
        if (!zoom) {
          return;
        }
  
        googleMap.setZoom(zoom - 1);
      } while (createVisibleStores() === 0);
    };
  
    const directionsPaneCloseBtn = document.getElementById(
      "rt_directions_pane_close_btn"
    )!;
  
    directionsPaneCloseBtn.onclick = clearDirections;
  }
  
  
  async function geolocateUser() {
    // actually fetch user position adn update application state
    const userPosition = await fetchUserPosition(position.defaultPosition);
    position.userPosition = userPosition;
    position.currentPosition = userPosition;
    position.directionsOrigin = userPosition;
  
    // update map center
    const newCenter = positionToLatLngLiteral(position.currentPosition);
    currentPositionMarker.setPosition(newCenter);
    googleMap.setOptions({
      center: newCenter,
      zoom: 12,
    });
  }
  
  function verifyUserPositionConsent() {
    if (userPositionUsageConsent) {
      geolocateUser();
  
      if (!userHasBeenGeolocated) {
        const { origin, type } = position.userPosition;
        switch (type) {
          case "html5": {
            origin === "fetched"
              ? rtEvent({
                  category: "StoreLocator",
                  action: "Geo",
                  label: "Success",
                })
              : rtEvent({
                  category: "StoreLocator",
                  action: "Geo",
                  label: "SuccessCookies",
                });
            break;
          }
          case "ip": {
            toast(retailtune.translations.k_warning_geolocation_erorr);
            origin === "fetched"
              ? rtEvent({
                  category: "StoreLocator",
                  action: "GeoIP",
                  label: "Success",
                })
              : rtEvent({
                  category: "StoreLocator",
                  action: "GeoIP",
                  label: "SuccessCookies",
                });
            break;
          }
          case "default": {
            toast(retailtune.translations.k_warning_geolocation_erorr);
            rtEvent({
              category: "StoreLocator",
              action: "GeoDefault",
              label: "Success",
            });
            break;
          }
        }
        userHasBeenGeolocated = true;
      }
    } else {
      // show modal
      htmlMap
        .get("consentModal")!
        .classList.add("rt-position-consent-modal--visible");
    }
  }
  
  function createFilteredStores() {
    filteredStores = new Array(stores.length);
    storeMarkers = new Array<google.maps.Marker>(filteredStores.length);
  
    let j = 0;
    for (let i = 0; i < stores.length; ++i) {
      if (
        !retailtune.storesFilter.storeType ||
        stores[i].storeTypeLabels.includes(retailtune.storesFilter.storeType)
      ) {
        filteredStores[j] = stores[i];
        storeMarkers[j] = storesMarkersMap.get(filteredStores[j].id)!;
        ++j;
      }
    }
    filteredStores.length = j;
    storeMarkers.length = j;
  
    markerCluster.clearMarkers();
    markerCluster.addMarkers(storeMarkers);
  }
  
  function createVisibleStores(): number {
    const mapBounds = googleMap.getBounds();
    if (!mapBounds) {
      setTimeout(createVisibleStores, 50);
      return -1;
    }
  
    visibleStores = new Array(filteredStores.length);
    let storePosition = { lat: 0, lng: 0 };
    let j = 0;
  
    const { latitude, longitude } = position.currentPosition;
    const getDistance = getRadialDistanceFn(latitude, longitude);
    for (let i = 0; i < filteredStores.length; ++i) {
      storePosition.lat = filteredStores[i].latitude;
      storePosition.lng = filteredStores[i].longitude;
      if (mapBounds?.contains(storePosition)) {
        visibleStores[j] = filteredStores[i];
        visibleStores[j].distance = getDistance(
          visibleStores[j].latitude,
          visibleStores[j].longitude
        );
        ++j;
      }
    }
    visibleStores.length = j;
  
    visibleStores.sort(sortStoresByPriority);
  
    // update stores count
    const storesCountEl = document.getElementById("rt_stores_count")!;
    const storesCountText =
      visibleStores.length === 1
        ? retailtune.translations.k_store_found
        : retailtune.translations.k_stores_found;
  
    storesCountEl.replaceChildren(
      <>
        <output>{visibleStores.length}</output>
        <span>{storesCountText}</span>
      </>
    );
  
    // update stores list
  
    const storesEl = document.getElementById("rt_stores")!;
    storesEl.textContent = "";
    for (let i = 0; i < visibleStores.length; ++i) {
      storesEl.appendChild(storeCardsMap.get(visibleStores[i].id)!);
    }
  
    visibleStores.length === 0
      ? htmlMap
          .get("backToNearestStoresBtn")!
          .classList.add("rt-back-to-nearest-store--visible")
      : htmlMap
          .get("backToNearestStoresBtn")!
          .classList.remove("rt-back-to-nearest-store--visible");
  
    return visibleStores.length;
  }
  
  function createStoreCard(store: Store) {
    // assuming servertime is already in milliseconds (as it should be)
    const todayTimeMilliseconds = retailtune.serverTime + store.hourOffset * 1000;
    let serverDayIndex = new Date(todayTimeMilliseconds).getDay();
    serverDayIndex = serverDayIndex === 0 ? 6 : serverDayIndex - 1;
    const dayname = retailtune.weekdayNames[serverDayIndex];
  
    const openingData = getStoreOpeningTime({
      format: "24h",
      serverTime: retailtune.serverTime,
      store,
    });
  
    function handleStoreClick() {
      const marker = storesMarkersMap.get(store.id)!;
      lastStoreClicked = {store, origin: 'list'};
      google.maps.event.trigger(marker, "click");
    }
  
    return (
      <li id={`rt_store_${store.id}`} class="rt-store" onclick={handleStoreClick}>
        <article>
          <header class="rt-store__heading">
            <span class="rt-store__type">{store.storeTypes[0]}</span>
            <h2 class="rt-store__name">{store.name}</h2>
          </header>
          <div class="rt-store__info">
            <span class="rt-store__address">
              {store.address1} - {store.postalCode} {store.city}{" "}
              {store.province ? `(${store.province})` : ""} {store.country.name}
            </span>
            {store.phone && (
              <a
                class="rt-store__phone"
                href={`tel:${store.phone}`}
                onclick={(e: Event) => {
                  e.stopPropagation();
                  handleStoreEvent("Phone", store, "list");
                }}
              >
                {store.phone}
              </a>
            )}
            {store.whatsapp && (
              <a
                class="rt-store__whatsapp"
                href={`https://wa.me/${store.whatsapp}`}
                onclick={(e: Event) => {
                  e.stopPropagation();
                  handleStoreEvent("Whatsapp", store, "list");
                }}
              >
                {retailtune.translations.k_store_chat_now}
              </a>
            )}
  
            {openingData.status === "ok" && (
              <span class="rt-store__opening-time">
                <span class="rt-dayname">{dayname}</span>
                <time
                  class={`rt-opening-time ${
                    openingData.isClosed ? "rt-opening-time--closed" : ""
                  }`}
                >
                  {openingData.isClosed
                    ? retailtune.translations.k_store_closed
                    : openingData.text}
                </time>
              </span>
            )}
          </div>
          <div class="rt-store__cta">
            {store.storeLink && (
              <a
                class="rt-btn rt-btn-primary"
                href={store.storeLink}
                onclick={(e: Event) => {
                  e.stopPropagation();
                  handleStoreEvent("Details", store, "list");
                }}
              >
                {retailtune.translations.k_store_info_and_promo}
              </a>
            )}
  
            <button
              class="rt-btn rt-btn-secondary"
              onclick={(e: Event) => {
                e.stopPropagation();
                handleDrivingDirections(store);
                handleStoreEvent("Phone", store, "list");
              }}
            >
              {retailtune.translations.k_store_driving_directions}
            </button>
            {store.services.includes(11) && (
              <a
                class="rt-btn rt-btn-tertiary"
                href={`${retailtune.assetBaseUrl}/booking/booking.php?ctaRT=${store.storeCode}-${retailtune.language}`}
                onclick={() => handleStoreEvent("Booking", store, "list")}
              >
                {retailtune.translations.k_store_booking}
              </a>
            )}
          </div>
        </article>
      </li>
    );
  }
  
  function createInfoWindow(store: Store) {
    return (
      <article class="rt-iw">
        <header class="rt-iw__heading">
          <span class="rt-store__type">{store.storeTypes[0]}</span>
          <h2 class="rt-iw__title">{store.name}</h2>
        </header>
        <div class="rt-iw__info">
          <span class="rt-iw__info-address">
            {store.address1}, {store.postalCode}
          </span>
          {store.phone && (
            <a
              class="rt-iw__info-phone"
              href={`tel:${store.phone}`}
              onclick={() => handleStoreEvent("Phone", store, "map")}
            >
              {store.phone}
            </a>
          )}
          {store.whatsapp && (
            <a
              class="rt-iw__info-whatsapp"
              href={`https://wa.me/${store.whatsapp}`}
              onclick={() => handleStoreEvent("Whatsapp", store, "map")}
            >
              {retailtune.translations.k_store_chat_now}
            </a>
          )}
        </div>
        <div class="rt-iw__cta">
          {store.storeLink && (
            <a
              class="rt-btn rt-btn-primary"
              href={store.storeLink}
              onclick={() => handleStoreEvent("Details", store, "map")}
            >
              {retailtune.translations.k_store_info_and_promo}
            </a>
          )}
          <button
            class="rt-btn rt-btn-secondary"
            onclick={() => {
              handleDrivingDirections(store);
              handleStoreEvent("Directions", store, "map");
            }}
          >
            {retailtune.translations.k_store_driving_directions}
          </button>
        </div>
      </article>
    );
  }
  
  function createLanguageSidebarContent() {
    const currentLang = retailtune.language.toUpperCase();
  
    return (
      <div class="rt-sidebar-wrapper">
        <header class="rt-sidebar__header">{currentLang}</header>
        <ul class="rt-sidebar__body">
          {retailtune.allLanguages.map((lang) => (
            <li
              class={`rt-sidebar__language ${
                currentLang === lang.code ? "rt-sidebar__language--current" : ""
              }`}
            >
              <a href={`${retailtune.homepageUrl}/${lang.code.toLowerCase()}`}>{lang.name}</a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  
  function createDirectionsInstructions() {
    if (!directionsStore) {
      return;
    }
  
    const origin = positionToLatLngLiteral(position.directionsOrigin);
    directionService.route(
      {
        origin,
        destination: {
          lat: directionsStore.latitude,
          lng: directionsStore.longitude,
        },
        travelMode,
      },
      (result, status) => {
        if (status !== "OK") {
          return toast(retailtune.translations.k_warning_directions_error);
        }
  
        if (result === null || !directionsStore) {
          return toast(retailtune.translations.k_warning_directions_no_result);
        }
  
        const destinationInputEl = document.getElementById(
          "rt_destination_input"
        )!;
        destinationInputEl.replaceChildren(
          createDestinationStoreLabel(directionsStore)
        );
  
        const destinationLabelEl = document.getElementById(
          "rt_destination_label"
        )!;
        destinationLabelEl.replaceChildren(
          createDestinationStoreLabel(directionsStore)
        );
  
        htmlMap
          .get("directionsPane")!
          .classList.add("rt-directions-pane--visible");
  
        // turn off marker cluster
        markerCluster.clearMarkers();
  
        // update origin marker position
        currentPositionMarker.setPosition(origin);
  
        // add destination store marker to map
        directionsStoreMarker = storesMarkersMap.get(directionsStore.id)!;
        directionsStoreMarker.setMap(googleMap);
  
        directionsRenderer.setDirections(result);
  
        const directionsSteps = htmlMap.get("directionsSteps")!;
        directionsSteps.replaceChildren(
          ...result.routes[0].legs[0].steps.map((step, i) => (
            <li class="rt-instruction">
              <strong>{i + 1}.</strong>
              <div innerHTML={step.instructions}></div>
            </li>
          ))
        );
      }
    );
  }
  
  function handleDrivingDirections(store: Store) {
    if (currentDevice === "desktop") {
      directionsStore = store;
      createDirectionsInstructions();
    } else {
      window.open(getGoogleMapsLink(store));
    }
  }
  
  function clearDirections() {
    // close directions pane
    htmlMap
      .get("directionsPane")!
      .classList.remove("rt-directions-pane--visible");
    // reset current position marker
    currentPositionMarker.setPosition(
      positionToLatLngLiteral(position.currentPosition)
    );
    // clear directionsStore
    directionsStore = null;
    // close any opened info window
    infoWindow.close();
    // clear destination store marker
    directionsStoreMarker?.setMap(null);
    directionsStoreMarker = null;
    // empty directions list
    htmlMap.get("directionsSteps")!.textContent = "";
    // clear directions polyline
    directionsRenderer.set("directions", null);
    // reset origin position
    position.directionsOrigin = position.userPosition;
    // turn on marker clusterer
    markerCluster.addMarkers(storeMarkers);
  }
  
  function createTravelMode(tMode: google.maps.TravelMode, iconUrl: string) {
    function handleTravelModeClick() {
      const travelModesList = document.querySelectorAll(
        ".rt-directions-pane__travel-mode"
      );
      travelModesList.forEach((tm) => {
        tm.classList.remove("rt-directions-pane__travel-mode--selected");
        if (tm.getAttribute("travel-mode") === tMode) {
          tm.classList.add("rt-directions-pane__travel-mode--selected");
        }
      });
  
      travelMode = tMode;
      createDirectionsInstructions();
    }
  
    return (
      <button
        class={`rt-directions-pane__travel-mode ${
          tMode === google.maps.TravelMode.DRIVING
            ? "rt-directions-pane__travel-mode--selected"
            : ""
        }`}
        onclick={handleTravelModeClick}
        travel-mode={tMode}
      >
        <img src={iconUrl} alt={tMode} width="40" height="40" />
      </button>
    );
  }
  
  function createDestinationStoreLabel(store: Store) {
    return (
      <>
        <img
          src={`${retailtune.assetBaseUrl}/img/pin/pin-destination.svg`}
          alt="pin icon"
        />
        <span>{store.name}</span>
      </>
    );
  }
  
  type EventKind =
    | "Directions"
    | "Store"
    | "Phone"
    | "Whatsapp"
    | "Details"
    | "Booking";
  function handleStoreEvent(kind: EventKind, store: Store, origin: CTAOrigin) {
    const eventName = `${kind}Click${origin === "list" ? "List" : "Map"}`;
    const label = `${eventName}-${store.city} ${store.address1}-${currentDevice}-${retailtune.language}`;
    rtEvent({ category: "Store", action: "Click", label, storeId: store.id });
  }
  
  let i = setInterval(() => {
    try {
      if (!(retailtune || google)) {
        throw new Error();
      }
      clearInterval(i);
      main();
    } catch (_) {}
  }, 50);
  