import { AnalyticsAction, AnalyticsCategory } from "@retailtune/types/lib/analytics";
import { Store } from "@retailtune/types/lib/store";
import { api, getDevice } from "@retailtune/utils";

export interface LanguageData {
  code: string;
  name: string;
  id: number;
  url: string;
}

interface RetailtuneEventParams {
  category: AnalyticsCategory;
  action: AnalyticsAction;
  label: string;
  storeId?: number;
}

declare function sendGa4DL (category: string, action: string, label: string, storeId: number): void;

export function rtEvent ({ category, action, label, storeId }: RetailtuneEventParams) {
  sendGa4DL(category, action, label, storeId ?? 0);
}


type EventKind = "Directions" | "Phone" | "Whatsapp" | "Booking" | "Email" | "Details";
type EventPage = "Sitemap" | "Concierge" | "Booking";
function _handleStoreEvent(store: Store, language: string, kind: EventKind, page: EventPage) {
  const eventName = `${kind}Click${page}`;
  const label = `${eventName}-${store.city} ${store.address1}-${getDevice()}-${language}`;
  rtEvent({ category: "Store", action: "Click", label, storeId: store.id });
}

export function handleConciergeEvent(store: Store, language: string, kind: EventKind) {
	_handleStoreEvent(store, language, kind, "Concierge");
}

export function handleBookingEvent(store: Store, language: string, kind: EventKind) {
	_handleStoreEvent(store, language, kind, "Booking");
}

export function handleSitemapEvent(store: Store, language: string, kind: EventKind) {
	_handleStoreEvent(store, language, kind, "Sitemap");
}


export async function fetchStores(rtKey: string, language: string): Promise<Store[]> {
  const res = await fetch(api.storesGetURL(), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${rtKey}`,
    },
    body: JSON.stringify({ language }),
  });

  if (!res.ok) {
    throw new Error(`cannot fetch store data, response status: ${res.status}`);
  }
  return await res.json();
}